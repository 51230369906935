<template>
  <div>
    <b-overlay :show="showTableOverlay" rounded="sm">
      <b-card style="max-width: 100%" class="mb-2">
        <div class="table-filters">
          <b-input-group class="mb-9">
            <b-form-input
              size="sm"
              aria-label="Name"
              :placeholder="$t('COMMON.NAME')"
              v-model="filterName"
              trim
            ></b-form-input>

            <b-input-group-append>
              <b-button
                size="sm"
                text="Search"
                variant="success"
                @click="onFilterSearch"
                >{{ $t("COMMON.SEARCH") }}</b-button
              >
              <b-button
                size="sm"
                text="Clear"
                variant="secondary"
                @click="onFiltersClear"
                >{{ $t("COMMON.CLEAR") }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>

        <b-table
          id="table"
          striped
          bordered
          :items="response.data"
          @sort-changed="sortingChanged"
          no-local-sorting
          responsive
          selectable
          select-mode="single"
          :fields="fields"
          @row-selected="onRowsSelected"
        ></b-table>

        <b-dropdown
          id="dropdown-show-by"
          :text="$t('COMMON.SHOW_BY') + ': ' + this.perPage"
          class="m-md-2"
          offset="-50"
          style="float: right"
        >
          <b-dropdown-item
            v-for="count in showPerPageArray"
            :key="count"
            @click="showPerPageChanged(count)"
            >{{ count }}</b-dropdown-item
          >
        </b-dropdown>

        <div
          v-bind:style="
            response.data.length === 0 || itemsTotal < perPage
              ? 'display:none;'
              : null
          "
        >
          <b-pagination
            v-model="currentPage"
            :page-class="!calculate ? 'hidden' : null"
            :last-class="!calculate ? 'hidden' : null"
            :first-class="!calculate ? 'hidden' : null"
            :hide-ellipsis="!calculate"
            :size="!calculate ? 'lg' : null"
            :per-page="perPage"
            @change="onPageSelected"
            :total-rows="itemsTotal"
            aria-controls="table"
          >
          </b-pagination>
        </div>
      </b-card>
    </b-overlay>

    <b-modal
      id="modal-delete-confirmation"
      :title="$t('COMMON.CONFIRMATION')"
      @ok="onDelete"
    >
      <p class="my-4" v-if="selectedItem">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE", {
            name: selectedItem.name,
          })
        }}
      </p>
    </b-modal>

    <b-modal
      id="modal-generate-report"
      :title="$t('REPORTS.GENERATE_REPORT')"
      @ok="generateOkClicked"
    >
      <b-overlay :show="generateReport.showOverlay" rounded="sm">
        <p class="my-4">
          {{
            $t("REPORTS.PLEASE_SPECIFY_PARAMETERS_FOR", {
              name: selectedItem != null ? selectedItem.name : "report",
            })
          }}
        </p>
        <b-form-group id="startDateGroup" label-for="field-date">
          <range-date-picker
            id="field-date"
            :start-date.sync="generateReport.startDate"
            :end-date.sync="generateReport.endDate"
          ></range-date-picker>
        </b-form-group>
        <br />

        <b-form-group>
          <multi-suggestion
            suggestion-processor="deviceSuggestionProcessor"
            :element-name="$t('DEVICE.DEVICE')"
            :elements-name="$t('DEVICE.DEVICES')"
            :selected-elements="generateReport.selectedDevices"
            :addErrorNotification="true"
            @on-element-added="onGenerateReportDeviceSelected"
            @on-remove-element="removeGenerateReportDevice"
          ></multi-suggestion>
        </b-form-group>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import Common from "../../common";
import SuggestionProcessorMixin from "../mixins/suggestionProcessorMixin";
import ApiService from "@/core/services/api.service";
import MultiSuggestion from "../component/MultiSuggestion";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import RangeDatePicker from "../component/RangeDatePicker";

export default {
  name: "reports",
  mixins: [validationMixin, SuggestionProcessorMixin],
  components: {
    MultiSuggestion,
    RangeDatePicker,
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          formatter: Common.idObjectFormatter,
          sortingKey: "el.id",
          sortable: false,
        },
        {
          key: "name",
          label: this.$t("COMMON.NAME"),
          sortingKey: "el.name",
          sortable: true,
        },
        {
          key: "description",
          label: this.$t("COMMON.DESCRIPTION"),
          sortingKey: "el.description",
          sortable: true,
        },
        {
          key: "global",
          label: this.$t("PARAMETERS.GLOBAL"),
          formatter: Common.booleanFormatter,
          sortingKey: "el.global",
          sortable: true,
        },
      ],

      filterName: "",

      response: {
        data: [],
        totalCount: 1,
      },
      showPerPageArray: [10, 50, 100],

      getList: {
        resource: "/api/report",
        requestType: "GET",
        requestParams: { enrich: true, teamId: ApiService.teamData.value },
      },

      currentPage: 1, // page numbers indexed from 1
      perPage: 10,

      showTableOverlay: true,

      errors: [],
      calculate: false,
      selectedItem: null,

      selectedUserId: null,
      filterUser: null,

      selectedIdentifierId: null,
      filterIdentifier: null,

      generateReport: {
        device: null,
        selectedDevices: [],
        startDate: null,
        endDate: null,
        showOverlay: false,
      },
    };
  },

  validations: {
    generateReport: {
      startDate: { required },
      endDate: { required },
    },
  },

  computed: {
    itemsTotal() {
      return this.response.totalCount;
    },
  },

  mounted() {
    let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.REPORTS") }]);
    this.$store.dispatch(SET_ACTIONS, [
      {
        label: this.$t("REPORTS.GENERATE"),
        action: this.onGenerateReportAction,
        icon: "fas fa-file-word",
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
      },
      {
        label: this.$t("ACTIONS.CREATE_NEW"),
        action: this.onCreateNewAction,
        icon: "fas fa-plus",
      },
      {
        label: this.$t("ACTIONS.EDIT"),
        action: this.onEditAction,
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
        icon: "fas fa-edit",
      },
      {
        label: this.$t("ACTIONS.DELETE"),
        action: this.onDeleteAction,
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
        icon: "fas fa-trash-alt",
      },
      {
        label: this.$t("ACTIONS.RELOAD"),
        action: this.onReloadAction,
        icon: "fas fa-sync",
      },
    ]);
  },

  created() {
    this.getList.requestParams.count = this.perPage;
    this.getList.requestParams.calculate = this.calculate;
    this.loadList();
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.generateReport[name];
      return $dirty ? !$error : null;
    },

    loadList: function () {
      this.showTableOverlay = true;
      this.$store
        .dispatch(API_REQUEST, this.getList)
        .then((response) => {
          this.response = Common.processEntityListResponse(
            this.response,
            response,
            this.currentPage,
            this.perPage,
            this.calculate
          );
          this.currentPage = this.response.noData
            ? this.currentPage - 1
            : this.currentPage;
          this.showTableOverlay = false;
        })
        .catch(this.onError);
    },

    onPageSelected: function (index) {
      this.getList.requestParams.start = (index - 1) * this.perPage;
      this.loadList();
    },

    onUserSelected: function (value) {
      this.selectedUserId = value.id;
    },

    onIdentifierSelected: function (value) {
      this.selectedIdentifierId = value.id;
    },

    onRowsSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedItem = items[0];

        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedItem = null;
      }
    },

    generateOkClicked: function (bvModalEvt) {
      if (this.generateReport.showOverlay) {
        bvModalEvt.preventDefault();
        return;
      }

      this.$v.generateReport.$touch();
      if (this.$v.generateReport.$anyError) {
        bvModalEvt.preventDefault();
        this.$bvToast.toast(this.$t("REPORTS.DATE_IS_NOT_SPECIFIED"), {
          title: this.$t("COMMON.WARNING"),
          variant: "warning",
          autoHideDelay: 5000,
        });
        return;
      }

      this.generateReport.showOverlay = true;
      const reportName =
        this.selectedItem.name +
        "-" +
        new Date().toLocaleDateString() +
        ".docx";
      const requestParams = {
        deviceIds: _.map(
          this.generateReport.selectedDevices,
          (device) => device.id
        ),
        startDate: this.generateReport.startDate,
        endDate: this.generateReport.endDate,
        teamId: ApiService.teamData.value,
      };

      bvModalEvt.preventDefault();

      this.axios
        .get("/api/report/generate/" + this.selectedItem.id, {
          responseType: "blob",
          params: requestParams,
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", reportName);
          document.body.appendChild(link);
          link.click();

          this.$bvModal.hide("modal-generate-report");
          this.generateReport.showOverlay = false;
        })
        .catch(async (response) => {
          this.generateReport.showOverlay = false;
          let body = JSON.parse(await response.response.data.text());
          //console.log(body);
          if (body.code == "BAD_REPORT") {
            this.$bvToast.toast(body.message, {
              title: this.$t("COMMON.ERROR"),
              variant: "danger",
              autoHideDelay: 5000,
            });
            return;
          }
          this.$bvToast.toast(response.message, {
            title: this.$t("COMMON.ERROR"),
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },

    getLang() {
      return localStorage.getItem("language") || "en";
    },

    onGenerateReportAction: function () {
      this.generateReport.selectedDevices = [];
      this.generateReport.endDate = null;
      this.generateReport.startDate = null;
      this.generateReport.showOverlay = false;
      this.$bvModal.show("modal-generate-report");
    },

    removeGenerateReportDevice(index, data) {
      this.generateReport.selectedDevices.splice(index, 1);
    },

    onGenerateReportDeviceSelected(data) {
      this.generateReport.selectedDevices.push(data);
    },

    onCreateNewAction: function () {
      this.$router.push({ name: "report" });
    },

    onDeleteAction: function () {
      this.$bvModal.show("modal-delete-confirmation");
    },

    onEditAction: function () {
      this.$router.push({
        name: "report",
        params: { id: this.selectedItem.id },
      });
    },

    onReloadAction: function () {
      this.getList.requestParams.start = 0;
      this.currentPage = 1;
      this.loadList();
    },

    onDelete: function () {
      const del = {
        resource: "/api/report/" + this.selectedItem.id + "/delete",
        requestType: "POST",
      };
      this.$store
        .dispatch(API_REQUEST, del)
        .then(() => {
          this.$bvToast.toast(this.$t("COMMON.DELETED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });

          // update table
          this.currentPage = 1;
          this.getList.requestParams.start = 0;
          this.loadList();
        })
        .catch(this.onError);
    },

    showPerPageChanged: function (count) {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = count;
      this.perPage = count;

      this.loadList();
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    sortingChanged: function (ctx) {
      this.currentPage = 1;
      this.getList.requestParams.sortDir = ctx.sortDesc ? "desc" : "asc";

      let field = this.fields.find((x) => x.key === ctx.sortBy);
      if (field != null && field.sortingKey != null) {
        this.getList.requestParams.sortField = field.sortingKey;
      } else {
        this.getList.requestParams.sortField = ctx.sortBy;
      }
      this.getList.requestParams.start = 0;
      this.loadList();
    },

    onFilterSearch: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams["name"] =
        this.filterName.length > 0 ? this.filterName : null;
      this.loadList();
    },

    onFiltersClear: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = this.perPage;

      delete this.getList.requestParams["name"];
      this.filterName = "";

      this.loadList();
    },
  },
};
</script>
