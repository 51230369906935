<template>
  <div class="bubbly-button">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BubblyButton",

  props: {
    eventName: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.$bus.$on(this.eventName, this.onTrigger);
  },

  beforeDestroy() {
    this.$bus.$off(this.eventName, this.onTrigger);
  },

  methods: {
    onTrigger() {
      this.$el.classList.add("animate");

      setTimeout(() => {
        this.$el.classList.remove("animate");
      }, 700);
    },
  },
};
</script>
