<template>
  <vue-typeahead-bootstrap
    :id="id + 'parameters'"
    v-model="filterParameters"
    :minMatchingChars="1"
    :show-on-focus="true"
    trim
    size="sm"
    @input="onInput(suggestionProcessor, filterParameters, flag)"
    :data="suggestedVariants"
    :serializer="(s) => s.name"
    @hit="onParameterSelected"
    :placeholder="$t('MULTI_SG.CHOOSE') + ' ' + elementName.toLowerCase()"
    style="min-width: 30%"
    :ref="id + 'parametersIdahead'"
  >
    <template slot="suggestion" slot-scope="{ data }">
      <div class="align-items-center">
        <!-- Note: the v-html binding is used, as htmlText contains
             the suggestion text highlighted with <strong> tags -->
        <!--<span class="ml-4" v-html="htmlText"></span>-->
        <span>{{ data.name }}</span>
        <template v-if="data.description && data.description !== ''">
          <br />
          <span>{{ data.description }}</span>
        </template>
      </div>
    </template>
    <template slot="append">
      <BubblyButton :event-name="id + '_multyselect_button'">
        <b-button
          :id="id + 'popover-button-variant'"
          tabindex="0"
          style="line-height: 14px"
          :disabled="_.size(this.selectedElements) === 0"
          :class="{
            'count--parameters__active': _.size(this.selectedElements) > 0,
          }"
          class="count--parameters"
        >
          {{ $t("MULTI_SG.SELECTED") + " " + elementsName.toLowerCase() }}:
          <span>{{ _.size(this.selectedElements) }}</span>
        </b-button>
      </BubblyButton>

      <b-popover
        v-if="_.size(this.selectedElements) > 0"
        :target="id + 'popover-button-variant'"
        placement="bottom"
        triggers="focus"
      >
        <b-list-group>
          <b-list-group-item
            v-for="(parameter, index) in this.selectedElements"
            :key="index"
            class="d-flex justify-content-between align-items-center"
            style="width: 200px"
          >
            {{ parameter.name }}

            <b-button-close
              title="remove from list"
              @click="onRemoveElement(parameter, index)"
            />
          </b-list-group-item>
        </b-list-group>
      </b-popover>
    </template>
  </vue-typeahead-bootstrap>
</template>

<script>
import BubblyButton from "@/view/component/bubbly-button";
import ApiService from "../../core/services/api.service";

export default {
  name: "MultiSuggestion",
  components: { BubblyButton },
  props: {
    suggestionProcessor: {
      type: String,
      required: true,
    },
    parameters: {
      type: String,
      default: "parameters",
    },
    flag: {
      type: String,
      default: "null",
    },
    addErrorNotification: {
      type: Boolean,
      default: false,
    },
    elementName: {
      type: String,
      required: true,
    },
    elementsName: {
      type: String,
      required: true,
    },
    selectedElements: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      filterParameters: "",
      doneTypingInterval: 500,
      typingTimer: null,
      suggestedVariants: [],
    };
  },

  created: function () {
    this.requestSuggestionVariants(
      this.suggestionProcessor,
      this.filterParameters,
      this.flag
    );
  },

  methods: {
    onInput(suggestionProcessorBeanName, query, flag) {
      clearTimeout(this.typingTimer);

      this.typingTimer = setTimeout(() => {
        this.requestSuggestionVariants(
          suggestionProcessorBeanName,
          query,
          flag
        );
      }, this.doneTypingInterval);
    },

    requestSuggestionVariants: function (
      suggestionProcessorBeanName,
      query,
      flag
    ) {
      let params = {
        teamId: ApiService.teamData ? ApiService.teamData.value : null,
        flags: flag,
        query: query,
      };

      if (flag === null || flag === undefined || flag === "null") {
        delete params.flags;
      }

      ApiService.query(
        "/platform/api/suggestion/" + suggestionProcessorBeanName,
        {
          params: params,
        }
      )
        .then((response) => {
          this.suggestedVariants = response.data;
        })
        .catch(this.onError);
    },

    onParameterSelected(data) {
      let isExist = false;
      this.selectedElements.some((element) => {
        if (element.id === data.id) isExist = true;
      });
      if (!isExist) {
        this.$emit("on-element-added", data);
      } else {
        if (this.addErrorNotification) {
          this.$bvToast.toast(this.elementName + " already added", {
            title: "Error",
            variant: "danger",
            autoHideDelay: 2500,
          });
        }
      }

      this.$nextTick(() => {
        this.suggestedVariants = [];
        this.filterParameters = "";
        this.$refs[this.id + "parametersIdahead"].$data.inputValue = "";
        this.$refs[this.id + "parametersIdahead"].$refs["input"].focus();
      });
    },
    onRemoveElement(data, index) {
      this.$emit("on-remove-element", index, data);
    },
  },

  watch: {
    selectedElements() {
      const triggerEventName = this.id + "_multyselect_button";
      this.$bus.$emit(triggerEventName);
    },
  },
};
</script>

<style scoped></style>
